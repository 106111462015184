import React, { Suspense, useRef } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Intro from "../components/PDP/Product/Intro"
import styled from "styled-components"
import { isBrowser } from "../services/core/browser"
import Fallback from "../components/Core/Fallback"
import FeaturesTicker from "../components/PDP/FeaturesTicker"
import { getPdpSchema } from "../services/core/schema"
import { getBundlePrices } from "../services/product/price"

const QuizCtaBlock = React.lazy(() => import("../components/Core/QuizCtaBlock"))
const Tips = React.lazy(() => import("../components/PDP/Tips"))
const FAQs = React.lazy(() => import("../components/PDP/FAQs"))
const RelatedProducts = React.lazy(() => import("../components/PDP/RelatedProducts"))
const ReviewsBlock = React.lazy(() => import("../components/PDP/ReviewsBlock"))
const Bundles = React.lazy(() => import("../components/PDP/Set/Bundles"))

const ProductContainer = styled.div`
  overflow: hidden;
`

const SetTemplate = (props) => {
  const isSSR = typeof window === "undefined"

  const path = props.location.pathname
  const { features, set, shopifyBundleProducts } = props.data

  const {
    metaTitle,
    metaDescription,
    images,
    additionalPdpImages,
    bundleDescription,
    title,
    bundleProducts,
    benefitsSectionTitle,
    ingredientsColumnTitle,
    slug,
    blocks,
    metaImage,
    showBadge,
    badgeLabel,
    bundleShortDescription,
    featureList,
    pdpPriceField,
    infoList,
    bundleVideo,
    bundleVideoImage,
    atcHeaderStep1,
    atcHeaderStep2,
    heroIngredientDisclaimers,
    heroProductSizeLabels,
    atcAutoRenewSubcopy,
    atcOneTimePurchaseSubcopy,
    atcAutoRenewSubcopyStep2,
    showBadgesImage,
    badgesImage,
  } = set

  let setGallery = []

  const reviewsRef = useRef(null)

  const handleRatingsClick = () => {
    if (isBrowser()) {
      const globalContainer = document.getElementById("global")
      if (reviewsRef.current && globalContainer) {
        const yOffset = -250
        const y =
          reviewsRef.current.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset
        globalContainer.scrollTo({ top: y, behavior: "smooth" })
      }
    }
  }

  const ingredientsData = props.pageContext.ingredientsData

  const allProductReviews = props.pageContext.productReviews

  let totalReviews = 0
  let ratingSum = 0

  allProductReviews.forEach((productReviewsObj) => {
    let productReviewsCount = 0

    const hasReviews = typeof productReviewsObj.productReviews !== "undefined";

    if (hasReviews) {
      productReviewsCount = productReviewsObj.productReviews.length

      productReviewsObj.productReviews.forEach((productReview) => {
        ratingSum += productReview.review.rating
      })
    }

    totalReviews += productReviewsCount
  })

  const averageRating = (ratingSum / totalReviews).toFixed(1)

  const productIdList = []

  if (images) {
    let selectedImage
    if (images.length >= 3) {
      selectedImage = images[2]
    } else {
      selectedImage = images[0]
    }
    setGallery.push(selectedImage)
  }

  const setProducts = []

  if (shopifyBundleProducts) {
    shopifyBundleProducts.nodes.forEach((product) => {
      let newProductObj = { ...product }
      newProductObj.images = newProductObj.media.map(mediaItem => mediaItem.image)
      let content

      if (bundleProducts) {
        content = bundleProducts.find(
          (productContent) => productContent.slug === product.handle,
        )
      }

      if (content) {
        newProductObj = Object.assign(newProductObj, content)
      }

      setProducts.push(newProductObj)
    })
  }

  let outOfStock = false

  setProducts.sort((a, b) => a.order - b.order)
  setProducts.forEach((product) => {
    if (product.tags && product.tags.length > 0) {
      product.tags.forEach(tag => {
        const sluggedTag = tag.toString().toLowerCase().replace(/ /g, "-")

        if (sluggedTag === "out-of-stock") {
          outOfStock = true
        }
      })
    }
    if (product.variants && product.variants.length > 0) {
      product.variants.forEach(variant => {

        if (variant.availableForSale === false) {
          outOfStock = true
        }
      })
    }

    let productImage = null
    if (product.images && product.images.length > 0) {
      productImage = product.images[0].src
    }
    const productId = parseInt(product.shopifyId.split("/").pop())
    productIdList.push({
      productId: productId,
      title: product.title,
      handle: product.handle,
      productImage: productImage,
    })
  })

  if (additionalPdpImages) {
    setGallery.push(...additionalPdpImages)
  }

  let mediaGallery = setGallery.map(mediaItem => {
    const { gatsbyImageData } = mediaItem
    return {
      contentType: "IMAGE",
      image: gatsbyImageData,
      video: null,
    }
  })

  if (bundleVideo) {
    mediaGallery.push({
      contentType: "VIDEO",
      image: bundleVideoImage ? {
        gatsbyImageData: bundleVideoImage.gatsbyImageData,
        src: bundleVideoImage.file.url,
      } : null,
      video: { src: bundleVideo.file.url },
    })
  }

  let schemaImages = []
  for (let i = 0; i < setGallery.length; i++) {
    schemaImages.push([`https:${setGallery[i]["file"]["url"]}`])
  }

  const productDetail = {
    title: title,
    images: mediaGallery,
    description: bundleDescription?.childMarkdownRemark?.html,
    price: pdpPriceField,
    products: setProducts,
    handle: slug,
    currency: "USD",
    set: true,
    outOfStock: outOfStock,
    showBadge,
    badgeLabel,
    shortDescription: bundleShortDescription.bundleShortDescription,
    featureList: featureList,
    infoList: infoList,
    atcHeaderStep1: atcHeaderStep1,
    atcHeaderStep2: atcHeaderStep2,
    disclaimers: heroIngredientDisclaimers,
    productSizeLabels: heroProductSizeLabels,
    oneTimeOptionSubcopy: atcOneTimePurchaseSubcopy,
    subscriptionOptionSubcopy: atcAutoRenewSubcopy,
    subscriptionOptionSubcopyStep2: atcAutoRenewSubcopyStep2,
    ampedUpsellProducts: props.pageContext.ampedUpsellProducts,
    badgesImage: badgesImage,
    showBadgesImage: showBadgesImage,
  }

  const stepOneProducts = setProducts.filter(product => {
    return !product.handle.includes("daily-scalp-essential")
  })

  const supplement = setProducts.filter(product => {
    return product.handle.includes("daily-scalp-essential")
  })

  const { oneTimeBundlePrice } = getBundlePrices(stepOneProducts)

  let schemaPrice = parseFloat(oneTimeBundlePrice.replace("$", ""))

  if (supplement.length === 1) {
    schemaPrice = schemaPrice + supplement[0].priceRangeV2?.minVariantPrice?.amount
  }

  let schemaInput = {
    title: title,
    imageSrcs: schemaImages,
    description: bundleShortDescription.bundleShortDescription,
    price: schemaPrice,
    inStock: !outOfStock,
    slug: slug,
    rating: averageRating,
    reviewCount: totalReviews,
  }

  const schema = getPdpSchema(schemaInput)

  const pageContents = []

  blocks.forEach((block, index) => {
    const { __typename: type } = block

    if (type === "ContentfulReviews") {
      pageContents.push(
        <ReviewsBlock
          {...block}
          key={index}
          productIdList={productIdList}
          ref={reviewsRef}
          isSet={true}
          reviewList={allProductReviews}
        />,
      )
    }
    if (type === "ContentfulTitleTextBlock") {
      pageContents.push(<Tips {...block} key={index} set />)
    }
    if (type === "ContentfulFaQs") {
      // pageContents.push(<FAQs {...block} key={index} set/>)
    }
    if (type === "ContentfulProductsCallout") {
      pageContents.push(<RelatedProducts {...block} key={index} set />)
    }
    if (type === "ContentfulQuizCallout") {
      pageContents.push(<QuizCtaBlock {...block} key={index} pdp />)
    }
  })

  let sharingImage = false

  if (metaImage) {
    sharingImage = metaImage.file.url
  }


  return (
    <Layout transparentHeader={true} schema={schema}>
      <Seo
        title={metaTitle}
        description={metaDescription?.metaDescription}
        path={path}
        image={sharingImage}
      />
      <ProductContainer>
        <Intro
          {...productDetail}
          totalReviews={totalReviews}
          averageRating={averageRating}
          handleRatingsClick={handleRatingsClick}
          isSet
        />
        <FeaturesTicker features={features.nodes} />
        {(!isSSR && setProducts) && (
          <Suspense fallback={<Fallback />}>
            <Bundles
              products={setProducts}
              title={benefitsSectionTitle}
              ingredientTitle={ingredientsColumnTitle}
              clearformeIngredients={ingredientsData}
            />
          </Suspense>
        )}
        {!isSSR && <Suspense fallback={<Fallback />}>
          {pageContents}
        </Suspense>}
      </ProductContainer>
    </Layout>
  )
}

export default SetTemplate

export const pageQuery = graphql`
  query SetTemplateQuery($slug: String!, $bundleProducts: [String!]!) {
    set: contentfulSet(slug: { eq: $slug }) {
      id
      metaTitle
      metaDescription {
        metaDescription
      }
      bundleShortDescription {
        bundleShortDescription
      }
      bundleDescription {
        childMarkdownRemark {
          html
        }
      }
      featureList
      infoList
      metaImage {
        file {
          url
        }
      }
      title
      heroIngredientDisclaimers
      heroProductSizeLabels
      price: heroStartingPrice
      benefitsSectionTitle
      ingredientsColumnTitle
      showBadge
      badgeLabel
      slug
      images: bundleImages {
        gatsbyImageData
        file {
          contentType
          url
        }
      }
      additionalPdpImages {
        gatsbyImageData
        file {
          contentType
          url
        }
      }
      bundleVideo {
        file {
          url
        }
      }
      bundleVideoImage {
        gatsbyImageData
        file {
          url
        }
      }
      bundleDescription {
        childMarkdownRemark {
          html
        }
      }
      bundleProducts {
        slug
        product_image_larger {
          gatsbyImageData
        }
        title
        order
        subscriptionDiscountText
        recommendedSubscription
        showBadge
        badgeLabel
        type
        setDescription {
          childMarkdownRemark {
            html
          }
        }
        blocks {
          ...benefitsFragment
          ...ingredientsFragment
        }
        atcPrepaidCopy
        atcPrepaidSubcopy
      }
      blocks {
        ...faqFragment
        ...reviewsFragment
        ...quizCalloutFragment
        ...productsCalloutFragment
        ...titleTextFragment
      }
      pdpPriceField
      atcHeaderStep2
      atcAutoRenewSubcopy
      atcOneTimePurchaseSubcopy
      atcAutoRenewSubcopyStep2
      showBadgesImage
      badgesImage {
        file {
          url
        }
      }
    }
    otherSets: allContentfulSet(filter: { slug: { ne: $slug } }) {
      nodes {
        ...contentfulSetFragment
      }
    }
    shopifyBundleProducts: allShopifyProduct(
      filter: { handle: { in: $bundleProducts } }
    ) {
      nodes {
        ...shopifyProductFragment
      }
    }
    features: allContentfulFeature(sort: { fields: order }) {
      nodes {
        ...featureFragment
      }
    }
  }
`
